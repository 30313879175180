<template>
  <v-app>
    <v-app-bar app dense flat color="primary">
      <Logo />
      <div class="pt-1 pl-2 text-uppercase subtitle-1 font-weight-medium tertiary--text">RPM Admin</div>
      <v-spacer></v-spacer>
      <app-switcher color="tertiary"></app-switcher>
      <ProfileMenu v-if="ssoState.user" color="tertiary" font="subtitle-2 font-weight-bold white--text" />
      <template v-if="ssoState.user" v-slot:extension>
        <v-tabs
          :value="selectedTabIndex"
          show-arrows
          @change="setSelectedTabIndex"
          dark
          class="px-4 elevation-0"
          color="accent"
        >
          <!-- <v-tab :to="{name: 'Home'}">Dashboard</v-tab> -->
          <v-tab :to="{ name: 'Orders' }">Orders</v-tab>
          <v-tab :to="{ name: 'Devices' }">Devices</v-tab>
          <v-tab :to="{ name: 'Patients' }">Patients</v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <v-main>
      <router-view :key="$route.fullPath" />
    </v-main>

    <!-- Refresh Snackbar -->
    <v-snackbar top centered :value="updateExists" :timeout="-1" color="white" light>
      <v-icon color="purple" left>mdi-information-outline</v-icon>There is a new version available.
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="refreshApp">Refresh</v-btn>
        <v-btn icon @click="closeRefresh">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <!-- Dev Mode Indicator -->
    <dev-mode-indicator v-if="isDevMode"></dev-mode-indicator>

    <!-- Auto Logout -->
    <auto-logout />

    <!-- Snackbars -->
    <snackbar-message ref="snackbar"></snackbar-message>
    <!-- <v-snackbar
      right
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      :color="snackbar.error ? 'error' : ''"
    >
      <v-icon v-if="snackbar.success" color="success">mdi-check</v-icon>
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-if="snackbar.showClose" text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar>-->
  </v-app>
</template>

<style lang="scss">
.clickable {
  cursor: pointer;
}

.v-btn,
.v-tab {
  text-transform: capitalize !important;
}

.center {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import Logo from './shared-ui/icons/NovoIcon.vue'
import AutoLogout from './shared-ui/components/AutoLogout.vue'
import ProfileMenu from './shared-ui/components/ProfileMenu.vue'
import AppSwitcher from './shared-ui/components/AppSwitcher.vue'
import DevModeIndicator from './shared-ui/components/DevModeIndicator.vue'
import update from './mixins/update'

import { getOrgs } from './shared-ui/store/org'
import SnackbarMessage from './shared-ui/components/SnackbarMessage.vue'
import { state as ssoState } from './shared-ui/store/sso'

export default defineComponent({
  name: 'App',

  components: {
    AutoLogout,
    AppSwitcher,
    DevModeIndicator,
    Logo,
    ProfileMenu,
    SnackbarMessage,
  },

  // TODO: Replace this with updated version from shared-ui
  mixins: [update],

  setup() {
    // const isDevMode = process.env.VUE_APP_ORDERS_API_URL !== 'https://api.admin.novorpm.com'
    const isDevMode = true

    const selectedTabIndex = ref(0)

    const setSelectedTabIndex = index => {
      selectedTabIndex.value = index
    }

    getOrgs()

    return {
      isDevMode,
      selectedTabIndex,
      setSelectedTabIndex,
      ssoState,
    }
  },
})
</script>
