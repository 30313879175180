import App from './App.vue'
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import VueShortkey from 'vue-shortkey'
import VueTheMask from 'vue-the-mask'
import dayjs from 'dayjs'
import dayjsCalendar from 'dayjs/plugin/calendar'
import { onAppReady } from './shared-ui/hooks'
import router from './router'
import vuetify from './plugins/vuetify'

dayjs.extend(dayjsCalendar)

Vue.config.productionTip = false

VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)
Vue.use(VueTheMask)
Vue.use(VueShortkey)

onAppReady(async () => {
  new Vue({
    router,
    vuetify,
    render: h => h(App),
  }).$mount('#app')
})
