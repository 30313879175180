<template>
  <div>
    <v-dialog light persistent v-model="showWarn" max-width="500" scrollable>
      <v-card>
        <v-card-title class="title">
          Still Here?
          <v-spacer></v-spacer>
          <v-progress-circular
            style="font-size: 13px"
            class="font-weight-bold ml-1 mr-n3 mt-n2"
            :rotate="-90"
            :size="32"
            :width="4"
            :value="countdownPercent"
            color="primary"
            >{{ countdown }}</v-progress-circular
          >
        </v-card-title>

        <v-card-text>
          <div class="py-2">
            Your current session will expire shortly. In order to keep Protected Health Information safe, we
            periodically check to see if you're still here. You can stay logged in by clicking below.
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn outlined color="primary" @click="navigateToSSO">Log Out</v-btn>
          <v-spacer></v-spacer>
          <v-btn depressed dark color="primary" @click="hideWarningMessage">Stay Logged In</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, onBeforeUnmount, ref } from '@vue/composition-api'
import { onAuthChanged } from '../hooks/auth'
import { clearLastActivity, getLastActivity, updateLastActivity } from '../helpers/activity'
import { navigateToSSO } from '../store/sso'
import { isStandalone } from '../store/url'

export default defineComponent({
  name: 'AutoLogout',
  props: {
    warnTimeout: {
      type: Number,
      default: 15 * 60 * 1000, // 15 minutes
    },
    defaultCountdown: {
      type: Number,
      default: 60, // 60 seconds
    },
    disabled: {
      type: Boolean,
      default: () => process.env.NODE_ENV === 'development',
    },
  },
  setup(props) {
    let checkTimer = null

    const events = ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load']
    const showWarn = ref(false)
    const countdown = ref(0)
    const countdownPercent = ref(0)

    const hideWarningMessage = () => {
      updateLastActivity()
      showWarn.value = false
    }

    const showWarningMessage = () => {
      showWarn.value = true
    }

    const startCheckTimer = () => {
      stopCheckTimer()
      checkTimer = setInterval(async () => {
        let lastActivity = getLastActivity()
        if (!lastActivity) {
          navigateToSSO()
        } else if (Date.now() - lastActivity >= props.warnTimeout) {
          showWarningMessage()
          const secondsLeft = Math.ceil((Date.now() - lastActivity - props.warnTimeout - props.defaultCountdown) / 1000)
          countdown.value = props.defaultCountdown - secondsLeft
          countdownPercent.value = 100 - (countdown.value / props.defaultCountdown) * 100
          if (countdown.value <= 0) {
            navigateToSSO({ auto: 1 })
          }
        } else if (showWarn.value) {
          hideWarningMessage()
        }
      }, 1000)
    }

    const stopCheckTimer = () => {
      clearInterval(checkTimer)
    }

    const updateActivity = () => {
      if (!showWarn.value) {
        updateLastActivity()
      }
    }

    const startListeners = () => {
      events.forEach(event => {
        window.addEventListener(event, updateActivity)
      })
      startCheckTimer()
    }

    const stopListeners = () => {
      hideWarningMessage()
      events.forEach(event => {
        window.removeEventListener(event, updateActivity)
      })
      stopCheckTimer()
    }

    onAuthChanged(authUser => {
      if (!authUser) {
        clearLastActivity()
      }
    })

    onBeforeMount(() => {
      if (!props.disabled && isStandalone) {
        startListeners()
      }
    })
    onBeforeUnmount(stopListeners)

    updateLastActivity()

    return {
      countdown,
      countdownPercent,
      hideWarningMessage,
      navigateToSSO,
      showWarn,
    }
  },
})
</script>