import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#202020',
        secondary: '#3F3F3F',
        tertiary: '#707070',
        accent: '#F5F5F5',
        accent1: '#00FBFF',
        accent2: '#FFDF6C',
        button: '#03A9F4',
        background: '#FFFFFF',
      },
    },
  },
})
