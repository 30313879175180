<template>
  <div v-show="ssoState.user" class="text-center">
    <v-menu v-model="showMenu" offset-y>
      <template v-slot:activator="{ on }">
        <v-btn depressed icon color="transparent" v-on="on" class="body-2">
          <user-avatar
            :color="color"
            :customStyle="{ color: 'white', fontWeight: 'bold' }"
            :image="photoUrl"
            :name="displayName"
          />
        </v-btn>
      </template>

      <v-card light width="300">
        <v-list dense class="pa-0">
          <v-list-item dense class="grey lighten-3">
            <v-row class="d-flex subtitle-2" justify="center">{{ orgName }}</v-row>
          </v-list-item>
          <v-divider></v-divider>
          <div dense class="d-flex flex-column align-center pt-6 pb-5">
            <user-avatar
              dark
              :image="photoUrl"
              :name="displayName"
              :size="80"
              color="primary"
              font="font"
              fontStyle="font-size: 36px"
            />
            <div class="pt-2" style="font-size: 18px">{{ displayName }}</div>
            <div class="subtitle-2 font-weight-light">{{ email }}</div>
          </div>
          <v-divider />
          <v-list-item @click="signOut">
            <v-list-item-title class="font-weight-regular d-flex align-center justify-center">
              <v-icon left class="ml-n2 mr-2">mdi-logout-variant</v-icon>Sign out
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import { state as ssoState, displayName, email, photoUrl, clearTokens } from '../store/sso'
import { getOrgs, state as orgState } from '../store/org'
import { broadcastAuthChanged } from '../hooks'
import UserAvatar from './UserAvatar.vue'

export default defineComponent({
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    font: {
      type: String,
      default: 'body-1 white--text',
    },
  },
  components: {
    UserAvatar,
  },
  setup() {
    const showMenu = ref(false)

    const orgName = computed(() => {
      if (ssoState.user && orgState.orgs) {
        for (let i = 0; i < orgState.orgs.length; i++) {
          const element = orgState.orgs[i]
          if (ssoState.user.orgId === element.id) {
            return element.name
          }
        }
      }
      return ''
    })

    const signOut = () => {
      // broadcast auth change (no auth user) to clear tokens
      clearTokens()
      broadcastAuthChanged(null)
    }

    getOrgs()

    return {
      displayName,
      email,
      orgName,
      photoUrl,
      showMenu,
      signOut,
      ssoState,
    }
  },
})
</script>