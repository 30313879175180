<template>
  <v-row class="home" justify="center">Welcome, home!</v-row>
</template>

<style lang="scss" scoped>
.loader {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.callback-snackbar {
  z-index: 1;
}
</style>
<style lang="scss">
tr {
  cursor: pointer;
}
.switch-font label {
  font-size: 14px !important;
  font-weight: 500;
}
.selected-row {
  background: #e0e0e0 !important;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

.patient-view .v-toolbar__extension {
  padding: 0 !important;
}
</style>

<script>
// import { mapState } from 'vuex'

export default {
  components: {},
  data: () => {
    return {}
  },
  // computed: {
  //   ...mapState('auth', ['authUser']),
  // },
}
</script>